.headerBoxMainContainer {
  /* background-color: #b93a3e; */
  /* background-color: #648467; */
  position: absolute;
  width: fit-content;
  height: fit-content;
  margin-left: 1.3vw;
  /* z-index: 1; */
  /* transition: 1s ease-in-out; */
  /* opacity: 0.9; */
}

.headerBox img {
  width: 14vw;
  height: 25%;
  margin: 0.7vw 4vw;
}
.homePageBorderLeft {
  position: absolute;
  width: 20px;
  /* border-top: 1px solid red;
  border-left: 1px solid red; */
  margin-top: 2.4vw;
  /* margin-left: 0.7vw; */
  margin-left: 15px;
  z-index: 9999;
  height: 100vh;
}
.homePageBorderTop {
  /* border-top: 1px solid red; */
  width: 100vw !important;
  margin-top: 2.4vw;
  margin-left: 20vw;
  position: absolute;
  width: 20px;
}
.homePageIcon1 {
  position: absolute;
  margin-left: 2vw;
  margin-top: 2.1vw;
}
.homePageIcon2 {
  position: absolute;
  margin-left: 19.5vw;
  margin-top: 2.1vw;
}
.header-box-bottom-img {
  height: 150px;
  width: 200px;
  padding: 0.6vw;
}

@media (max-width: 992px) {
  .headerBox img {
    width: 40%;
  }
}

/* @media (max-width: 992px) {
  .headerBoxMainContainer {
    width: 80%;
    padding-left: 24%;
    height: auto;
  }
  .homePageBorderLeft {
    border: none;
  }
  .homePageBorderTop {
    border: none;
  }

  .homePageIcon2 {
    display: none;
  }
  .homePageIcon1 {
    display: none;
  }
  .headerBox img {
    width: 50%;
  }
} */

.cart_items {
  position: absolute;
  top: 40px;
  margin-left: 1100px;
  padding: -30%;
  top: 20%;
}

.cart_items button {
  border-radius: 100px;
  background-color: #275207;
  border: none;
  width: 120px;
  height: 40px;
  text-align: initial;
  font-weight: bold;
  color: white;
}
.cart_items div {
  background-color: rgb(245, 109, 109);
  color: white;
  position: relative;
  top: -30px;
  right: -90px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 1000px;
}

.mainwebsite button {
  background-color: #275207;
  color: white;
  width: 170px;
  height: 40px;
  border: none;
  border-radius: 40px;
  position: absolute;
  left: 400px;
  top: 20px;
}
@media (max-width: 600px) {
  .headerBoxMainContainer {
    position: relative;
    z-index: 9999;
    width: 100%;
    /* padding-left: 20%; */
    margin-left: 0%;
    height: 100%;
    /* background-color: #fff; */

    background-image: url(../Home/assets/bg5.jpg);
    padding-bottom: 10px;
    position: sticky;
    top: 0;
  }

  .headerBox img {
    width: 50% !important;
    margin-bottom: -10% !important;
  }
  .cart_items {
    position: relative;
    top: 10px;
    margin-left: 65%;
    color: white;
  }

  .mainwebsite button {
    position: relative;
    margin-left: -370px;
    top: 60px;
  }
}
@media (max-width: 600px) {
  .menu_btn {
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 20px;
    cursor: pointer;
    transition: all 0.5s ease-out;
  }

  /* Styling the hamburger lines */
  .menu_btn .btn_line {
    width: 28px;
    height: 3px;
    margin: 0 0 5px 0;
    background: rgb(0, 0, 0);
    transition: all 0.5s ease-out;
  }
  .backgroundnavbar {
    position: relative;
    height: 100px;
  }
}

.restBanner {
  background-image: url(../Home/assets/bg5.jpg);
  /* height: 106.5vh; */
  height: 100vh;
  background-size: cover;
  /* background-color: #ffff; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.checkOutContainer {
  margin-top: 16vh;
}
@media (max-width:600px) {
  .checkOutContainer
  {
    margin-top: 29vh;
  }
  
}
.mainContainer {
  width: 100%;
  height: 75vh;
  overflow-y: auto;
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
}
.formMainContainer {
  /* display: flex; */
  padding: 10px 0px;
  justify-content: space-around;
}
.formMainContainer div {
  flex-basis: 33%;
}
.formMainContainer label {
  display: block;
  margin: 5px 0px;
}
.formFieldCheckbox {
  text-align: left;
  padding: 5px;
}
.formFieldCheckbox label {
  display: inline;
  margin-left: 10px;
}
.formFieldCheckbox input {
  display: inline;
  width: fit-content !important;
}
.billingAddress {
  background-color: #f2fde6;
  padding: 10px;
  margin: 10px;
}
.billingAddress h3 {
  text-align: center;
  margin: 10px 0px;
}
.shippingMethod {
  background-color: #f2fde6;
  margin: 10px;
}
.shippingDetails {
  background-color: #f2fde6;
  text-align: left;
  padding: 10px;
}
.shippingMethod h3 {
  text-align: center;
  margin: 10px 0px;
}
.paymentMethod {
  background-color: #f2fde6;
  margin: 10px;
}
.paymentMethod h3 {
  text-align: center;
  margin: 10px 0px;
}
.formField {
  margin: 5px 10px;
}
.formField input {
  width: 100%;
}
/* .billingAddress */
.twoColumn {
  display: flex;
}
.twoColumn div {
  flex-basis: 50%;
}
/* orders */
/* .orderReview {
  margin: 10px 0px;
  background-color: #f2fde6;
  padding: 10px;
} */

.orderReview {
  background-color: #f2fde6;
  padding: 12px;
  margin: 10px 0px;
  width: 100%;
}
.orderReview table {
  width: 100%;
  margin-top: 20px;
}
.orderReview thead {
  margin: 10px;
}
.orderReview table tr td {
  padding: 10px 0px;
}
.proceedBtnContainer {
  text-align: center;
  margin-top: 20px;
}
.proceedBtn {
  background-color: rgb(194, 255, 199);
  padding: 10px;
  font-weight: 600;
}
/* 
.billingAddres {
  background-color: rgb(194, 255, 199);
  padding: 10px;
  margin: 10px 0px;
}
.billingAddres input {
  margin: 10px 20px;
}
.shippingMethod {
  background-color: rgb(194, 255, 199);
  padding: 10px;
  margin: 10px 0px;
}
.billingAddres input {
  margin: 10px 20px;
}
.paymentMethod {
  background-color: rgb(194, 255, 199);
  padding: 10px;
  margin: 10px 0px;
}
.paymentMethod input {
  margin: 10px 20px;
}
.orderReview {
  background-color: rgb(194, 255, 199);
  padding: 10px;
  margin: 10px 0px;
  width: 100%;
}
.orderReview table {
  width: 80%;
}
.orderReview thead {
  margin: 10px;
}
.orderReview table tr td {
  padding: 10px 0px;
}
.proceedBtnContainer {
  text-align: center;
  margin-top: 20px;
}
.proceedBtn {
  background-color: rgb(194, 255, 199);
  padding: 10px;
} */

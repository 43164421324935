.restBanner {
  background-image: url(../Home/assets/bg5.jpg);
  /* height: 106.5vh; */
  height: 100vh;
  background-size: cover;
  /* background-color: #ffff; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.productContainer {
  margin-top: 16vh;
}

.mainContainer {
  width: 100%;
  height: 72vh;
  overflow-y: auto;
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
}
.mainContainer::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

.mainContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.mainContainer::-webkit-scrollbar-thumb {
  background: #275207;
  border-radius: 10px;
}

.mainTitle {
  color: #000;
  font-size: 30px;
  text-align: center;
  padding: 10px 0px;
}
.productImg {
  width: 100%;
  display: flex;
  justify-content: center;
}
.image {
  justify-content: center;
  text-align: center;
}
.productImg div {
  flex-basis: 50%;
}
.productDescTitle {
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0px;
}
.image {
  align-self: center;
  justify-content: center;
}
.image img {
  width: 350px;
  justify-self: center;
  margin-left: 10%;
}
.productDesc {
  padding: 10px;
}
.productDesc img {
  width: 100%;
}
.textAvailable {
  text-align: center;
}
.textAvailable p {
  font-size: 16px;
  background-color: #f2fde6;
  padding: 10px 0px;
  font-weight: bold;
}
.tableValues {
  margin-bottom: 20px;
}
.tableValues table {
  width: 90%;
  margin: 0 auto;
}
.tableValues table tr th {
  text-align: center;
  border: 2px solid black;
  padding: 10px;
}
.tableValues td {
  text-align: center;
  border: 2px solid black;
  padding: 5px;
}
.tableValues svg {
  color: #275207;
  font-size: 30px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .restBanner {
    /* height: 106.5vh; */
    height: 100%;

    overflow: auto;
  }

  .productContainer {
    margin-top: 30px;
  }
  .productImg {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .image img {
    margin-left: 0;
  }
}

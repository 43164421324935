.restBanner {
  background-image: url(../Home/assets/bg5.jpg);
  /* height: 106.5vh; */
  height: 100vh;
  background-size: cover;
  /* background-color: #ffff; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.cartContainer {
  margin-top: 16vh;
}


.mainContainer {
  width: 80%;
  margin-left: 10%;
  height: 75vh;
  overflow-y: auto;
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  /* width: 80%;
  height: 550px;
  margin: 0 auto;
  overflow-y: auto;
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%); */
}

.mainContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}

.mainContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.mainContainer::-webkit-scrollbar-thumb {
  background: #275207;
  border-radius: 10px;
}


.textAvailable {
  margin: 10px;
  /* background-color: rgb(194, 255, 199); */
  background-color: #060c00;
  padding: 10px;
  font-size: bold;
}
.productContainerTitle {
  /* display: flex; */
  /* padding: 10px 20px; */
  /* position: absolute; */
  
}
.productContainerTitle th {
  /* flex-basis: 20%; */
  width: 150px;
  background-color: rgb(141, 197, 141);
  text-align: center;
  padding: 10px;
  font-weight: bold;
 
}
.productContainer {
  /* position:static ;
  display: flex; */
  padding: 10px 20px;
   /* margin-left: 5%; */
  
}
.productContainer td  {
  flex-basis: 20%;

  text-align: center;
  padding: 10px;
  /* background-color: rgb(194, 255, 199); */
  background-color: #f2fde6;
}
.quantity
{
  width: 40px;
}

.prodQuan select {
  width: 60%;
  text-align: center;
}
.prodQuan select option {
  /* background-color: rgb(194, 255, 199); */
  background-color: #f2fde6;
}
.deliveryCheckout {
  display: flex;
  padding: 20px;
}
.deliveryCheckout div {
  flex-basis: 50%;
}
.deliveryCheckout p{
  color: green;
} 
.deliveryCheckoutTotal {
  text-align: center;
  float: right;
  
}
.deliveryCheckoutTotal table {
  width: 110%;
  margin-left: -15%;
  border-collapse: separate;
  border-spacing: 0 15px;
}
.deliveryCheckoutTotal p {
  color: rgb(184, 0, 0);
  font-size: 15px;
  padding-bottom: 10px;
  /* border-bottom: 5px solid red; */
}

.deliveryCheckoutStyle td
{
  text-align:left;
  width: 60%;
}
/* .deliveryCheckoutStyle :nth-child(1)
{
  border: #0a9c05 solid 2px;
} */
.proceedBtn {
  text-align: right;
  padding: 0px 10px;
  
}
.proceedBtn button {
  background-color: #275207;
  margin: 10px;
  color: white;
  padding: 8px;
  
}
.proceedBtn button:nth-child(2)
{
  margin-left: 5%;
}
tr :nth-child(1) {
  font-weight: bold;
}

.removeButton button
{
  position:relative;
  top: 90%;
}
.flightCharge
{
  
    font-weight: bold ;
    margin-right: 40px;
    margin-top: 20px ;
    width: 400px ;
  
}
@media only screen and (max-width: 976px) {
  .deliveryCheckout
  {
    position: relative;
    display: grid;
    justify-content: center;    
  }
  .deliveryCheckoutTotal
  {
    position: relative;
    margin-left: 10%;
  }  

  
}
@media (max-width:600px) {
  .cartContainer
  {
    margin-top: 24vh;
  }
  .tablecontainer
  {
    font-size: 15px;
  }
  .mainContainer {
    width: 100%;
    margin-left:0 ;
    
  }
  .flightCharge
{
  
    font-weight: bold;
    margin-right: 0px ;
    margin-top: 0px;
    width: 0px ;
  
}
.deliveryCheckoutTotal table {
  /* width: 100%; */
  margin-left: -20%;
  border-collapse: separate;
  border-spacing: 0 15px;
}
.deliveryCheckoutTotal p
{
  width: auto;
  /* margin-right: 30%; */
}
}
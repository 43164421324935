.restBanner {
    background-image: url(../Home/assets/bg5.jpg);
    /* height: 106.5vh; */
    height: 100vh;
    background-size: cover;
    /* background-color: #ffff; */
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
  }
  .checkOutContainer {
    margin-top: 16vh;
  }
  .mainContainer
  {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  }
  .detailContainer
  {
    border: 0px solid green;
    border-radius: 30px;
    width: 80%;
    margin: 0 auto;
    background-color: #f2fde6;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .finaldetail
  {
   margin-left: 25%; 
   /* border: 1px red solid; */
   width: 60%;
   
  }
  .finaldetail tr
  {
    background-color: rgb(141, 197, 141);
  }
  .finaldetailAmount
  {
    margin-left: 25%;
    /* border: 1px green solid; */
    width: 60%;
    font-weight: bold;
    /* border-radius: 20px; */
   
  }
.finaldetailAmount tr
{
  background-color: rgb(141, 197, 141);
}

.proceedBtnContainer {
    text-align: center;
    margin-top: 20px;
    /* margin-bottom: 20px; */
  }

  .proceedBtn {
    background-color: rgb(194, 255, 199);
    padding: 10px;
    font-weight: 600;
  }
